import React, { useState } from "react";
import styled from "styled-components";

const AccessCodeInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const AccessCodeInput = styled.input`
  width: 100%;
  max-width: 300px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  color: #5e5e5e;
  font-size: 16px;
  outline: none;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.04);

  &::placeholder {
    color: rgba(255, 255, 255, 1);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  }

  &:focus {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

const VerifyButton = styled.button`
  border: none;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }
`;

const AccessCodeInputComponent = ({ onVerify }) => {
  const [accessCode, setAccessCode] = useState("");

  return (
    <AccessCodeInputContainer>
      <AccessCodeInput
        type="text"
        placeholder="Enter Access Code"
        value={accessCode}
        onChange={(e) => setAccessCode(e.target.value)}
      />
      <VerifyButton onClick={() => onVerify(accessCode)}>Verify</VerifyButton>
    </AccessCodeInputContainer>
  );
};

export default AccessCodeInputComponent;
