import React from 'react';
import './Switch.css';

/**
 * A controlled Switch component that toggles between two states.
 * 
 * @param {Object} props
 * @param {boolean} props.isToggled
 * @param {Function} props.onToggle
 */
const Switch = ({ isToggled, onToggle }) => {
  return (
    <div className={`switch-container ${isToggled ? 'toggled' : ''}`} onClick={onToggle}>
      <div className="switch-circle"></div>
    </div>
  );
};

export default Switch;