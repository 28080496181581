import React from 'react';
import styled from 'styled-components';

const CodeTypeSelectorContainer = styled.div`
  text-align: center;
  margin-top: 40px;
  padding: 30px;
  border-radius: 16px;
  background: linear-gradient(135deg, #309be3, #b5e2ff);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1120px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25), 0 1px 3px rgba(0, 0, 0, 0.08);
`;

const Separator = styled.div`
  height: 2px;
  width: 80%;
  background-color: #ccc;
  margin: 10px auto;
`;

const HowToUseContainer = styled.div`
  transition: opacity 0.5s ease;
  opacity: ${({ show }) => (show ? 1 : 0)};
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  overflow-y: auto;
  text-align: center;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  font-family: fonarto;
  color: #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
`;

const CodeTypeSelector = ({ setSelectedCodeType, selectedCodeType, howToUseText, isComponentSelected }) => {
  return (
    <CodeTypeSelectorContainer>
      {isComponentSelected && (
        <>
          <Separator />
        </>
      )}
      <HowToUseContainer show={!!howToUseText}>
        {howToUseText}
      </HowToUseContainer>
    </CodeTypeSelectorContainer>
  );
};

export default CodeTypeSelector;