import React from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/theme-cobalt';


const CodeSnippet = ({ code }) => (
  <AceEditor
    mode="javascript"
    theme="cobalt"
    name="code_snippet"
    value={code}
    readOnly={true}
    fontSize={14}
    width="700px"
    height="745px"
    setOptions={{ useWorker: false, tabSize: 3 }}
  />
);

export default CodeSnippet;