import React, { useState, useEffect } from 'react';

const MouseTagDisplay = () => {
  const [tag, setTag] = useState('');
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isVisible, setIsVisible] = useState(false);
  const [timer, setTimer] = useState(null);
  const funnyQuotes = [
    "It's magic ✨",
    "This confuses you too? 🙈",
    "Nothing to see here 🚀",
    "Tag, you're it! 🏷️"
  ];

  useEffect(() => {
    const handleMouseMove = (e) => {
      setIsVisible(false);
      if (timer) {
        clearTimeout(timer);
      }
      const element = document.elementFromPoint(e.clientX, e.clientY);
      const isInsidePhoneFrame = element.closest('.phoneFrame') !== null;
      const delay = isInsidePhoneFrame ? 6000 : 2000;
      const newTimer = setTimeout(() => {
        if (isInsidePhoneFrame) {
          const randomQuote = funnyQuotes[Math.floor(Math.random() * funnyQuotes.length)];
          setTag(randomQuote);
        } else if (element && element.tagName && !['HTML', 'BODY'].includes(element.tagName)) {
          setTag(`<${element.tagName.toLowerCase()}>`);
        } else {
          setTag('');
        }
        setPosition({ x: e.clientX, y: e.clientY });
        setIsVisible(true);
      }, delay);
  
      setTimer(newTimer);
    };
  
    document.addEventListener('mousemove', handleMouseMove);
  
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  const modalStyle = {
    position: 'fixed',
    left: `${position.x}px`,
    top: `${position.y + 20}px`,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    padding: '10px 15px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    zIndex: 1000,
    fontSize: '16px',
    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
    color: '#333',
    maxWidth: '300px',
    wordWrap: 'break-word',
    textAlign: 'center',
    opacity: isVisible ? 1 : 0,
    transition: 'opacity 0.5s ease-in-out'
  };

  return (
    tag && (
      <div style={modalStyle}>
        {tag}
      </div>
    )
  );
};

export default MouseTagDisplay;