import React from 'react';
import Header from './Header';
import './MobileWarning.css';

const MobileWarning = () => (
  <div className="mobileWarningContainer">
    <div className="headerContainer">
      <Header />
    </div>
    <div className="mobileWarningMessage">
      <p>This site is optimized for desktop viewing. Please access it on a desktop for the best experience.</p>
    </div>
    <div className="footerContent">
      © {new Date().getFullYear()} FlowUi - All rights reserved.
    </div>
  </div>
);

export default MobileWarning;