import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaAngleRight, FaRegChartBar, FaSlidersH, FaUsers , FaBars, FaGripVertical, FaShoppingCart, FaFan} from 'react-icons/fa'; 
import { MdOutlineRadioButtonChecked, MdFormatListBulleted, MdCircleNotifications } from "react-icons/md";
import { IoIdCard } from "react-icons/io5";
import './Header.css';
import styled, { keyframes } from 'styled-components';
import AccessCodeInput from './AccessCodeInput';
import PaidModal from './PaidModal'; // Import the PaidModal component

const SidebarContainer = styled.div`
  width: 190px;
  background: linear-gradient(135deg, #309be3, #b5e2ff);
  float: left;
  box-shadow: 4px 0 8px rgba(0, 25, 0, 0.02);
  color: #fff;
  padding: 20px;
  border-radius: 16px;
  top: 170px;
  position: fixed;
  transition: height 0.5s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25), 0 1px 3px rgba(0, 0, 0, 0.08);
  overflow: hidden;
`;


const MenuList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-top: 20px;
`;

const MenuTitle = styled.li`
  padding: 10px 0;
  margin-top: -20px;
  font-weight: bold;
  font-size: 24px;
  text-align: left;
  color: #fff;
  font-family: fonarto;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
`;

const MenuItem = styled.li`
  cursor: pointer;
  opacity: ${({ expanded, allCollapsed }) => (expanded || allCollapsed ? 1 : 0.3)};
  transition: opacity 0.5s ease;
  padding: 10px 0;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-family: fonarto;
  font-size: 18px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);

  &:hover {
    color: #b3e5fc;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  }
`;

const SubMenuList = styled.ul`
  list-style-type: none;
  padding-left: 20px;
  margin-top: 5px;
`;

const SubMenuItem = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  color: #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

  &:hover {
    color: #b3e5fc;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  }
`;

const animateGradient = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const GradientSeparator = styled.div`
  height: 2px;
  background: linear-gradient(90deg, rgba(255,255,255,0.1), rgba(255,255,255,0.8), rgba(255,255,255,0.1));
  background-size: 200% 200%;
  animation: ${animateGradient} 3s linear infinite;
  margin: 5px 0;
`;

const Sidebar = ({ onComponentSelect }) => {
  const [expandedMenus, setExpandedMenus] = useState({});
  const [totalHeight, setTotalHeight] = useState(0);
  const [hasAccess, setHasAccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const menuRefs = useRef({});

  useEffect(() => {
    const allMenusExpanded = Object.keys(menus).reduce((acc, menuName) => {
      acc[menuName] = false;
      return acc;
    }, {});
    setExpandedMenus(allMenusExpanded);

    const access = localStorage.getItem('hasAccess');
    if (access === 'true') {
      setHasAccess(true);
    }
  }, []);

  const toggleMenu = (menuName) => {
    setExpandedMenus((prevExpandedMenus) => {
      const isExpanded = !prevExpandedMenus[menuName];
      const updatedExpandedMenus = {
        ...Object.keys(prevExpandedMenus).reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {}),
        [menuName]: isExpanded,
      };

      let newTotalHeight = 0;
      Object.keys(updatedExpandedMenus).forEach((key) => {
        if (updatedExpandedMenus[key] && menuRefs.current[key]) {
          newTotalHeight += menuRefs.current[key].scrollHeight;
        }
      });

      setTotalHeight(newTotalHeight);

      return updatedExpandedMenus;
    });
  };

  const verifyAccessCode = async (code) => {
    try {
      const response = await fetch('/api/verify-access', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ accessCode: code }),
      });
      const data = await response.json();
      if (data.success) {
        setHasAccess(true);
        localStorage.setItem('hasAccess', 'true');
      } else {
        alert(data.message);
      }
    } catch (error) {
      console.error('Error verifying access code:', error);
    }
  };

  const menus = {
    Buttons: {
      icon: <MdOutlineRadioButtonChecked style={{ marginRight: '10px' }} />,
      items: [
        { name: 'Button', onSelect: () => onComponentSelect('Button') },
        { name: 'Animated Flip Button', onSelect: () => onComponentSelect('AnimatedButton') },
        { name: 'Basic Play Button', onSelect: () => onComponentSelect('BasicPlayButton') },
      ],
    },
    Charts: {
      icon: <FaRegChartBar style={{ marginRight: '10px' }} />,
      items: [
        { name: 'Pie Chart', onSelect: () => onComponentSelect('PieChart') },
        { name: 'Bar Graph', onSelect: () => onComponentSelect('BarGraph') },
      ],
    },
    Dropdowns: {
      icon: <FaBars style={{ marginRight: '10px' }} />,
      items: [
        { name: 'Basic Dropdown', onSelect: () => onComponentSelect('BasicDropdown') },
        { name: 'Menu Search', onSelect: () => onComponentSelect('MenuSearch') },
      ],
    },
    Forms: {
      icon: <MdFormatListBulleted style={{ marginRight: '10px' }} />,
      items: [
        { name: 'Form', onSelect: () => onComponentSelect('Form') },
        { name: 'Contact Us', onSelect: () => onComponentSelect('ContactUs') },
        { name: 'Search', onSelect: () => onComponentSelect('Search') },
        { name: 'Playful Login', onSelect: () => onComponentSelect('PlayfulLogin')}
      ],
    },
    Inidcators: {
      icon: <MdCircleNotifications style={{ marginRight: '10px' }} />,
      items: [
        { name: 'Notification', onSelect: () => onComponentSelect('Notification') },
        { name: 'Happy Loading Spinner', onSelect: () => onComponentSelect('HappyLoadingSpinner') },
      ],
    },
    Sliders: {
      icon: <FaSlidersH style={{ marginRight: '10px' }} />,
      items: [
        {name: 'Basic Slider', onSelect: () => onComponentSelect('BasicSlider') },
        {name: 'Gradient Slider', onSelect: () => onComponentSelect('GradientSlider') },
        {name: 'Switch', onSelect: () => onComponentSelect('Switch') },
      ],
    },
    Carousels: {
      icon: <FaGripVertical style={{ marginRight: '10px' }} />,
      items: [
        {name: 'Vertical Carousel', onSelect: () => onComponentSelect('VerticalCarousel') },
        {name: 'Scrolling Carousel', onSelect: () => onComponentSelect('ScrollingCarousel') },
      ],
    },
    Effects: {
      icon: <FaFan style={{ marginRight: '10px' }} />,
      items: [
        { name: 'Confetti Effect', onSelect: () => onComponentSelect('ConfettiEffect') },
        { name: 'Interactive 3D Card', onSelect: () => onComponentSelect('InteractiveCard') },
      ],
    },
    Commerce: {
      icon: <FaShoppingCart style={{ marginRight: '10px' }} />,
      items: [
        { name: 'Popover Button', onSelect: () => onComponentSelect('PopoverButton') },
        { name: 'Product Card', onSelect: () => onComponentSelect('ProductCard') },
        { name: 'Product Ratings', onSelect: () => onComponentSelect('ProductRatings') },
      ],
    },
    Social: {
      icon: <FaUsers style={{ marginRight: '10px' }} />,
      items: [
        { name: 'Basic Chat', onSelect: () => onComponentSelect('BasicChat') },
        { name: 'Mutual Friends', onSelect: () => onComponentSelect('MutualFriends') },
      ],
    },
    "Advanced Buttons": {
      Paid: true,
      icon: <MdOutlineRadioButtonChecked style={{ marginRight: '10px' }} />,
      items: [
        { name: 'Expanding Button', onSelect: () => {
          if (!hasAccess) {
            setShowModal(true);
          } else {
            onComponentSelect('ExpandingButton');
          }
        }},
      ],
    },
    "Cards": {
      Paid: true,
      icon: <IoIdCard style={{ marginRight: '10px' }} />,
      items: [
        { name: 'Profile Card', onSelect: () => {
          if (!hasAccess) {
            setShowModal(true);
          } else {
            onComponentSelect('ProfileCard');
          }
        }},
      ],
    },
  };

  // Add a base style for the menu items container
  const menuItemContainerStyle = {
    overflow: 'hidden',
    transition: 'max-height 0.5s ease',
    '&.collapsed': {
      transition: 'max-height 0.2s ease-out',
    },
  };

  // Check if all menus are collapsed
  const allMenusCollapsed = Object.values(expandedMenus).every(status => !status);

  return (
    <>
      <SidebarContainer style={{ height: `${hasAccess ? (555 + totalHeight) : (625 + totalHeight)}px` }}>
        {!hasAccess && <AccessCodeInput onVerify={verifyAccessCode} />}
        <MenuList>
          <MenuTitle>Components</MenuTitle>
          {Object.keys(menus).map((menuName) => {
            const isLastFreeSection = menuName === "Social";
            return (
              <React.Fragment key={menuName}>
                <MenuItem
                  onClick={() => toggleMenu(menuName)}
                  expanded={expandedMenus[menuName]}
                  allCollapsed={allMenusCollapsed}
                >
                  {menus[menuName].icon}{menuName}
                </MenuItem>
                <div
                    ref={(el) => (menuRefs.current[menuName] = el)}
                    style={{
                      ...menuItemContainerStyle,
                      maxHeight: expandedMenus[menuName] ? '500px' : '0',
                    }}
                    className={expandedMenus[menuName] ? '' : 'collapsed'}
                  >
                  <SubMenuList>
                    {menus[menuName].items.map((component) => (
                      <SubMenuItem key={component.name} onClick={() => {
                        if (menus[menuName].Paid && !hasAccess) {
                          setShowModal(true);
                        } else {
                          component.onSelect();
                        }
                      }}>
                        {component.name}
                      </SubMenuItem>
                    ))}
                  </SubMenuList>
                </div>
                {isLastFreeSection && <GradientSeparator />}
              </React.Fragment>
            );
          })}
        </MenuList>
      </SidebarContainer>
      <PaidModal isOpen={showModal} onClose={() => setShowModal(false)} />
    </>
  );
};

export default Sidebar;
