import React from 'react';
import styled from 'styled-components';

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: linear-gradient(135deg, #e0f7fa, #1e88e5);
  padding: 30px;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25), 0 1px 3px rgba(0, 0, 0, 0.08);
  width: 90%;
  max-width: 500px;
  text-align: center;
  color: #fff;
  font-family: fonarto;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 3.5rem;
  border: none;
  background: none;
  cursor: pointer;
  color: #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

  &:hover {
    color: #b3e5fc;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  }
`;

const PremiumTitle = styled.h2`
  font-size: 2rem;
  margin-top: -15px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
`;

const PremiumText = styled.p`
  font-size: 1.2rem;
  margin-bottom: 30px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
`;

const PatreonLink = styled.a`
  display: inline-block;
  background-color: #fff;
  color: #1e88e5;
  padding: 10px 20px;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #b3e5fc;
  }
`;

const PaidModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <ModalBackdrop onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <PremiumTitle>Premium Component</PremiumTitle>
        <PremiumText>
          Unlock the full potential of your project with our premium components. Subscribe to our Patreon today and gain exclusive access to the entire<br/> Flow UI library!
        </PremiumText>
        <PatreonLink href="https://www.patreon.com/FlowUI" target="_blank" rel="noopener noreferrer">
          Subscribe on Patreon
        </PatreonLink>
      </ModalContent>
    </ModalBackdrop>
  );
};

export default PaidModal;