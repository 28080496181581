import React, { useState } from 'react';
import styled from 'styled-components';
import DOMPurify from 'dompurify';

const EditorContainer = styled.div`
  padding: 40px;
  border-radius: 16px;
  background: linear-gradient(135deg, #e0f7fa, #1e88e5);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
`;

const MiniPhoneFrame = styled.div`
  width: 120px;
  height: 240px;
  border: 4px solid #000;
  border-radius: 24px;
  position: absolute;
  top: 10px;
  right: 40px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 8px;
    border-radius: 4px;
    background-color: #000;
  }
`;

const BottomNav = styled.div`
  width: 100%;
  height: 10px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 4px;
    border-radius: 2px;
    background-color: white;
  }
`;

const ComponentScaler = styled.div`
  transform: scale(0.5);
  transform-origin: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EditorTitle = styled.h3`
  font-size: 32px;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  cursor: pointer;
  user-select: none;
  transition: color 0.3s ease;

  &:hover {
    color: #b3e5fc;
  }
`;

const PartContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const PartTitle = styled.h4`
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  cursor: pointer;
`;

const StyleRow = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
`;

const StyleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  flex: 1;
`;

const StyleLabel = styled.label`
  min-width: 120px;
  font-size: 18px;
  color: #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  margin-right: 15px;
`;

const StyleInput = styled.input`
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 18px;
  color: #333;
  transition: box-shadow 0.3s ease;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(30, 136, 229, 0.4);
  }
`;

const DynamicStyleEditor = ({ dynamicStyles, updateDynamicStyles, DynamicComponent, isSwitchToggled, toggleSwitch }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedParts, setExpandedParts] = useState({});

  const handleChange = (part, styleName, value) => {
    const sanitizedValue = DOMPurify.sanitize(value);
    const updatedPartStyles = {
      ...dynamicStyles[part],
      [styleName]: sanitizedValue,
    };
    const updatedStyles = {
      ...dynamicStyles,
      [part]: updatedPartStyles,
    };
    updateDynamicStyles(updatedStyles);
  };

  const toggleExpanded = () => {
    setIsExpanded((prevExpanded) => !prevExpanded);
  };

  const togglePartExpanded = (part) => {
    setExpandedParts(prevExpandedParts => ({
      ...prevExpandedParts,
      [part]: !prevExpandedParts[part],
    }));
  };

  return (
    <EditorContainer>
      {isExpanded && (
        <MiniPhoneFrame>
          {DynamicComponent && (
            <ComponentScaler>
              <DynamicComponent
                isToggled={isSwitchToggled}
                onToggle={toggleSwitch}
                style={dynamicStyles}
              />
            </ComponentScaler>
          )}
          <BottomNav />
        </MiniPhoneFrame>
      )}
      <EditorTitle onClick={toggleExpanded}>Edit Styles</EditorTitle>
      {isExpanded && (
        <>
          {Object.entries(dynamicStyles).map(([part, styles]) => (
            <PartContainer key={part}>
              <PartTitle onClick={() => togglePartExpanded(part)}>{part}</PartTitle>
              {expandedParts[part] && (
                Object.entries(styles).map(([styleName, value]) => (
                  <StyleContainer key={styleName}>
                    <StyleLabel>{styleName}:</StyleLabel>
                    <StyleInput
                      type="text"
                      value={value}
                      onChange={(e) => handleChange(part, styleName, e.target.value)}
                    />
                  </StyleContainer>
                ))
              )}
            </PartContainer>
          ))}
        </>
      )}
    </EditorContainer>
  );
};

export default DynamicStyleEditor;