import React, { useState, useEffect } from "react";
import CodeSnippet from "./CodeSnippet";
import parse from "html-react-parser";
import ExpandableSection from "./common/ExpandableSection";
import Switch from "./Switch/Switch"; // Import the Switch component
import DynamicStyleEditor from "./DynamicStyleEditor"; // Import the DynamicStyleEditor component
import styled from "styled-components";

const ShowcaseContainer = styled.div`
  margin-top: 40px;
  padding: 40px;
  border-radius: 16px;
  background: linear-gradient(135deg, #309be3, #b5e2ff);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25), 0 1px 3px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-left: 315;
`;

const ShowcaseTitle = styled.h2`
  text-align: left;
  color: #fff;
  font-family: fonarto;
  font-size: 32px;
  font-weight: bold;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  margin-bottom: -20px;
  margin-top: -10px;
`;

const ShowcaseContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const CodeSnippetContainer = styled.div`
  flex: 1;
`;

const Spacer = styled.div`
  height: 50px; /* Adjust the height as needed */
`;

const ShowcaseComponent = ({
  selectedComponent,
  codeType,
  setHowToUseText,
}) => {
  const [componentCodeSnippet, setComponentCodeSnippet] = useState("");
  const [stylingCodeSnippet, setStylingCodeSnippet] = useState("");
  const [demoUrl, setDemoUrl] = useState("");
  const [DynamicComponent, setDynamicComponent] = useState(null);
  const [isSwitchToggled, setIsSwitchToggled] = useState(false);
  const [dynamicStyles, setDynamicStyles] = useState({}); // Initialize as empty and will be set dynamically

  useEffect(() => {
    async function fetchComponentData() {
      try {
        // Dynamically import the component module
        const importedComponent = await import(
          `./${selectedComponent}/${selectedComponent}.js`
        );
        setDynamicComponent(() => importedComponent.default);

        // Extract and set editable styles if defined
        const editableStyles = importedComponent.default.editableStyles || {};
        setDynamicStyles(editableStyles);

        // Additional data fetching as before
        const snippetsModule = await import(
          `./${selectedComponent}/CodeSnippets`
        );
        const howToModule = await import(`./${selectedComponent}/HowTo`);
        if (codeType === "html") {
          const initialCodeSnippet = snippetsModule.htmlCode;
          let finalCodeSnippet = initialCodeSnippet;
          if (howToModule.howToUseJava) {
            finalCodeSnippet = `${initialCodeSnippet}\n\nJAVASCRIPT CODE\n\n${howToModule.howToUseJava}`;
          }
          setComponentCodeSnippet(finalCodeSnippet);
          setHowToUseText(howToModule.howToUseButtonHTML);
        } else if (codeType === "reactNative") {
          setComponentCodeSnippet(snippetsModule.reactNativeCode);
          setHowToUseText(howToModule.howToUseButtonReactNative);
        }
        setStylingCodeSnippet(
          codeType === "html"
            ? snippetsModule.htmlStyling
            : snippetsModule.reactNativeStyling
        );
        if (howToModule.appDemoUrl) {
          setDemoUrl(howToModule.appDemoUrl);
        }
      } catch (error) {
        console.error("Failed to load component data:", error);
      }
    }

    fetchComponentData();
  }, [selectedComponent, codeType, setHowToUseText]);

  const formatComponentName = (name) => {
    if (name === "AnimatedButton") {
      return "Animated Flip Button";
    } else if (name === "InteractiveCard") {
      return "Interactive 3D Card";
    }
    return name.replace(/([A-Z])/g, " $1").trim();
  };

  const toggleSwitch = () => {
    setIsSwitchToggled(!isSwitchToggled);
  };

  const updateDynamicStyles = (newStyles) => {
    setDynamicStyles(newStyles);
  };

  return (
    <ShowcaseContainer>
      <ShowcaseTitle>{formatComponentName(selectedComponent)}</ShowcaseTitle>
      {selectedComponent === "Switch" && (
        <ShowcaseContent>
          <CodeSnippetContainer>
            <CodeSnippet code={componentCodeSnippet} />
          </CodeSnippetContainer>
          <div
            className="phoneFrame"
            style={{ backgroundColor: isSwitchToggled ? "black" : "white" }}
          >
            <div className="componentScaler">
              {DynamicComponent && (
                <DynamicComponent
                  isToggled={isSwitchToggled}
                  onToggle={toggleSwitch}
                  style={dynamicStyles}
                />
              )}
            </div>
          </div>
        </ShowcaseContent>
      )}
      {selectedComponent !== "Switch" && (
        <ShowcaseContent>
          <CodeSnippetContainer>
            <CodeSnippet code={componentCodeSnippet} />
          </CodeSnippetContainer>
          {codeType === "reactNative" && DynamicComponent ? (
            <div className="phoneFrame">
              <div className="componentScaler">
                <DynamicComponent style={dynamicStyles} />
              </div>
            </div>
          ) : (
            demoUrl && (
              <iframe
                src={demoUrl}
                width="370px"
                height="800px"
                frameBorder="0"
                scrolling="no"
                style={{ marginTop: "20px" }}
              ></iframe>
            )
          )}
        </ShowcaseContent>
      )}
      {Object.keys(dynamicStyles).length > 0 && (
        <DynamicStyleEditor
          dynamicStyles={dynamicStyles}
          updateDynamicStyles={updateDynamicStyles}
          DynamicComponent={DynamicComponent}
          isSwitchToggled={isSwitchToggled}
          toggleSwitch={toggleSwitch}
        />
      )}
    </ShowcaseContainer>
  );
};

export default ShowcaseComponent;