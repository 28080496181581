import React, { useEffect, useRef } from 'react';
import './Header.css';
import logo from '../../assets/images/logo.png';

const Header = ({ isComponentSelected }) => {
  const headerRef = useRef(null);
  const h1Ref = useRef(null);
  const canvasRef = useRef(null);
  const speedMultiplierRef = useRef(1);

  useEffect(() => {
    let lastX = 0;
    let lastY = 0;
    let lastTime = Date.now();

    const handleMouseMove = (event) => {
      if (!isComponentSelected) {
        const currentTime = Date.now();
        const dx = event.clientX - lastX;
        const dy = event.clientY - lastY;
        const distance = Math.sqrt(dx * dx + dy * dy);
        const timeElapsed = currentTime - lastTime;
        const speed = distance / timeElapsed;

        if (speed > 0.5) {
          speedMultiplierRef.current = Math.min(5, speedMultiplierRef.current + 0.1);
        }

        lastX = event.clientX;
        lastY = event.clientY;
        lastTime = currentTime;
      }
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isComponentSelected]);

  useEffect(() => {
    const canvas = canvasRef.current = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    headerRef.current.appendChild(canvas);
    const setCanvasSize = () => {
      canvas.width = headerRef.current.offsetWidth;
      canvas.height = headerRef.current.offsetHeight;
    };
    setCanvasSize();
    window.addEventListener('resize', setCanvasSize);

    canvas.style.position = 'absolute';
    canvas.style.left = '0';
    canvas.style.top = '0';
    canvas.style.pointerEvents = 'none';
    canvas.style.zIndex = '0';

    let particles = [];
    for (let i = 0; i < 50; i++) {
      particles.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        radius: Math.random() * 3 + 1,
        vx: (Math.random() * 2 - 1),
        vy: (Math.random() * 2 - 1)
      });
    }

    function draw() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      particles.forEach(particle => {
        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
        ctx.fillStyle = '#fff';
        ctx.fill();

        particle.x += particle.vx * speedMultiplierRef.current;
        particle.y += particle.vy * speedMultiplierRef.current;

        if (particle.x < 0 || particle.x > canvas.width) particle.vx *= -1;
        if (particle.y < 0 || particle.y > canvas.height) particle.vy *= -1;
      });

      speedMultiplierRef.current = Math.max(1, speedMultiplierRef.current - 0.02);

      requestAnimationFrame(draw);
    }

    draw();

    return () => {
      window.removeEventListener('resize', setCanvasSize);
      if (headerRef.current && canvas.parentNode === headerRef.current) {
        headerRef.current.removeChild(canvas);
      }
    };
  }, []);

  return (
    <header ref={headerRef} className="header">
      <h1 ref={h1Ref} className="h1">Fl&nbsp;&nbsp;w UI</h1>
      <img src={logo} alt="Logo" className={`logo ${isComponentSelected ? 'spin glow' : ''}`} />
    </header>
  );
};

export default Header;