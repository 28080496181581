import React, { useState, useEffect } from "react";
import { Dimensions } from "react-native";
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"
import "./App.css";
import Header from "./components/common/Header";
import Sidebar from "./components/common/Sidebar";
import ShowcaseComponent from "./components/ShowcaseComponent";
import CodeTypeSelector from "./components/CodeTypeSelector";
import MouseTagDisplay from "./components/common/MouseTagDisplay";
import MobileWarning from "./components/common/MobileWarning";

function App() {
  const [selectedCodeType, setSelectedCodeType] = useState("reactNative");
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [howToUseText, setHowToUseText] = useState("");
  const [triggerSpin, setTriggerSpin] = useState(false);

  const components = [
    { name: "Button" },
    { name: "AnimatedFlipButton" },
    { name: "PopoverButton"},
    { name: "MutualFriends" },
    { name: "BasicSlider" },
    { name: "PieChart"},
    { name: "GradientSlider" },
    { name: "BasicPlayButton" },
    { name: "BasicDropdown" },
    { name: "ProductRatings" },
    { name: "Form" },
    { name: "BasicChat"},
    { name: "Notification"},
    { name: "HappyLoadingSpinner"},
    { name: "VerticalCarousel"},
    { name: "Switch"},
    { name: "ContactUs"},
    { name: "Search"},
    { name: "ScrollingCarousel"},
    { name: "ExpandingButton"},
    { name: "BarGraph"},
    { name: "ProductCard"},
    { name: "MenuSearch"},
    { name: "ProfileCard"},
    { name: "ConfettiEffect"},
    { name: "InteractiveCard"},
    { name: "PlayfulLogin"},
  ];

  const handleComponentSelect = (componentName) => {
    setSelectedComponent(componentName);
    setTriggerSpin(true);
  };

  useEffect(() => {
    if (triggerSpin) {
      const timer = setTimeout(() => {
        setTriggerSpin(false);
      }, 750);
      return () => clearTimeout(timer);
    }
  }, [triggerSpin]);

  const { width } = Dimensions.get('window');
  const isMobile = width < 768;

  return (
    <div className="App">
      {isMobile ? (
        <MobileWarning />
      ) : (
        <>
          <MouseTagDisplay />
          <SpeedInsights />
          <Analytics />
          <Header isComponentSelected={triggerSpin} />
          <div style={{ display: "flex", height: "calc(100vh - 60px)" }}>
            <Sidebar
              components={components}
              onComponentSelect={handleComponentSelect}
            />
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                overflow: "visible",
                marginLeft: "220px",
              }}
            >
              {selectedComponent ? (
                <ShowcaseComponent
                  selectedComponent={selectedComponent}
                  codeType={selectedCodeType}
                  setHowToUseText={setHowToUseText}
                />
              ) : <ShowcaseComponent
                  selectedComponent={components[0].name}
                  codeType={selectedCodeType}
                  setHowToUseText={setHowToUseText}
                />}
              <CodeTypeSelector
                setSelectedCodeType={setSelectedCodeType}
                selectedCodeType={selectedCodeType}
                howToUseText={howToUseText}
                isComponentSelected={!!selectedComponent}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default App;

